import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal2',
  templateUrl: './modal2.component.html',
  styleUrls: ['./modal2.component.sass']
})
export class Modal2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
