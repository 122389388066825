export class MessageActionState {
  public opened:boolean;
  public deleted:boolean;
  public deletedForEver: boolean;

  public MessageActionState() {
    this.opened = false;
    this.deleted = false;
    this.deletedForEver = false;
  }
}
