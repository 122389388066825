import { Component, OnInit } from '@angular/core';
import { ContactService } from '../../service/contact-service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.sass']
})
export class ContactComponent implements OnInit {
  public name:string;
  public name2: string;
  public email:string;
  public tel:string;
  public message: string;

  public nameResponse: Response;
  public emailResponse: Response;
  public emailResponse2: Response;
  public telResponse: Response;
  public messageResponse: Response;
  public successResponse: Response;

  constructor(private contact: ContactService) {
  }

  ngOnInit() {
   
  }

  sendMessage() {
    this.nameResponse = null;
    this.emailResponse = null;
    this.telResponse = null;
    this.messageResponse = null;
    this.successResponse = null;
    this.emailResponse2 = null;

    let values: string[] = [this.name, this.email, this.tel, this.message];

    if (!this.areNullOrUndefinedOREmpty(values)) {
      if (this.email.includes('@')) {
        //could add validation for .com/.net.etc
        this.contact.sendMessage(this.name, this.email, this.tel, this.message);
        this.successResponse = new Response(TypeOfField.success,
          this.contact.response);

        this.name = '';
        this.email = '';
        this.tel = '';
        this.message = '';

      } else {
        this.emailResponse2 = new Response(TypeOfField.success, 'Email is not in the correct format');
      }
    } else {
      if (this.isNullOrUndefinedOREmpty(this.name)) {
        this.nameResponse = new Response(TypeOfField.name, 'Name cant be left black');
      } 

      if (this.isNullOrUndefinedOREmpty(this.email)) {
        this.emailResponse = new Response(TypeOfField.email, 'email cant be left blank');
      } 

      if (this.isNullOrUndefinedOREmpty(this.tel)) {
        this.telResponse = new Response(TypeOfField.tel, 'tel cant be left blank');
      } 
      if (this.isNullOrUndefinedOREmpty(this.message)){
        this.messageResponse = new Response(TypeOfField.message, 'message cant be left blank');
      }
    }

  }

  private isNullOrUndefinedOREmpty(value:string):boolean {
    return value == undefined || value == '' || value == null;
  }

  private areNullOrUndefinedOREmpty(values: string[]): boolean {
    let ret = false;
    values.forEach((item) => {
      if (item == undefined || item == '' || item == null) {
        ret = true;
      }
    });

    return ret;
  }

}

export class Response {
  type: TypeOfField;
  message: string;

  constructor(type: TypeOfField, message:string) {
    this.type = type;
    this.message = message;
  }
}

enum TypeOfField {
  success,
  name,
  email,
  tel,
  message
}
